import React, {ChangeEvent, Fragment, useState} from "react";
import {Button, Card, CardBody, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {ConversationProps} from "@apis/api/search";
import avatar2 from "@assets/utils/images/avatars/0.jpg";
import axios, {CancelTokenSource} from "axios";
import {selectedFile} from "@layout/foldertree/FolderTree";

interface InputTextAreaProps {
    conversations: ConversationProps[];
    setSelectedPage?: React.Dispatch<React.SetStateAction<{ fileName: string; page: number } | null>>;
    setConversations: React.Dispatch<React.SetStateAction<ConversationProps[]>>;
    setFileName?: React.Dispatch<React.SetStateAction<selectedFile>>;
    setKeywords?: React.Dispatch<React.SetStateAction<string[]>>;
    searchFunction: (conversations: ConversationProps[],
                     setConversations: React.Dispatch<React.SetStateAction<ConversationProps[]>>,
                     newQuestion: ConversationProps,
                     cancelTokenSource: CancelTokenSource,
                     fileName?: string) => Promise<boolean | undefined>;
    fileName?: string;
    originalFileName?: string;
}

const InputTextArea = (props: InputTextAreaProps) => {
    const source = axios.CancelToken.source();
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [cancelTokenSource, setCancelTokenSource] = useState(source);

    const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    };

    const handleQuestionSample = async (sample: string) => {
        setKeyword(sample);
        await handleQuestion(sample);
    }

    const handleQuestion = async (inputKeyword?: string) => {
        const questionKeyword = inputKeyword !== undefined ? inputKeyword : keyword;
        const newQuestion: ConversationProps = {
            message: questionKeyword,
            timestamp: new Date().toLocaleTimeString(),
            avatar: avatar2,
            isUser: true,
            role: "user",
        };
        props.setConversations([...props.conversations, newQuestion]);

        setKeyword("");
        setLoading(true);
        // props.setFileName && props.setFileName({name: undefined, fileName: undefined});
        props.setSelectedPage && props.setSelectedPage(null);
        props.searchFunction(props.conversations,
            props.setConversations,
            newQuestion,
            cancelTokenSource,
            props.fileName)
            .then((result) => {
                if (!result) {
                    console.log("error");
                }
                setLoading(false);
            });
    };

    const handleCancel = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel("Operation canceled by the user.");
            setCancelTokenSource(axios.CancelToken.source());
            setLoading(false);
        }
    };

    const handleClear = () => {
        setKeyword("");
        // props.setFileName && props.setFileName({name: undefined, fileName: undefined});
        props.setSelectedPage && props.setSelectedPage(null);
        props.setConversations([]);
    };

    return (
        <Fragment>
            <Card className={"main-card mb-3"}>
                <CardBody>
                    {props.fileName === "한화생명 시그니처 암보험 3.0 (비갱신형) 무배당.pdf" ?
                        <Fragment>
                            <Button
                                className="mb-2 me-2 btn-transition"
                                style={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                                onClick={() => handleQuestionSample("암 관련 약관 내용 보여줘")}>
                                암 관련 약관 내용 보여줘
                            </Button>
                            <Button
                                className="mb-2 me-2 btn-transition"
                                style={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                                onClick={() => handleQuestionSample("MRI 촬영에 도움되는 약관 내용 알려줘")}>
                                MRI 촬영에 도움되는 약관 내용 알려줘
                            </Button>
                        </Fragment> :
                        <Fragment>
                            <Button
                                className="mb-2 me-2 btn-transition"
                                style={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                                onClick={() => handleQuestionSample("암 관련 약관 내용 알려줘")}>
                                암 관련 약관 내용 알려줘
                            </Button>
                            <Button
                                className="mb-2 me-2 btn-transition"
                                style={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                                onClick={() => handleQuestionSample("자동차 사고 후유증 보험금 지급 사유")}>
                                자동차 사고 후유증 보험금 지급 사유
                            </Button>
                        </Fragment>
                    }

                    <InputGroup>
                        <Form.Control placeholder={"Message Chatbot..."}
                                      value={keyword}
                                      onChange={handleKeywordChange}
                                      onKeyDown={(e) => {
                                          if (e.nativeEvent.isComposing || e.nativeEvent.code === "229") {
                                              return;
                                          } else if (e.key === "Enter") {
                                              handleQuestion();
                                          }
                                      }}/>
                        <Button color={"secondary"} onClick={() => handleQuestion()}>
                            {loading ? (
                                    <FontAwesomeIcon icon={faSpinner}
                                                     spin
                                                     style={{color: "white"}}/>)
                                :
                                ("Send")}
                        </Button>
                        <Button color={"secondary"} onClick={handleCancel}>Stop</Button>
                        <Button color={"secondary"} onClick={handleClear}>Clear</Button>
                    </InputGroup>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default InputTextArea;
