import {Dispatch, SetStateAction, useState} from "react";
import Styled from "styled-components";
import RowContainer from "@layout/foldertree/RowContainer";
const Container = Styled.div`
    padding: 0rem 1.5rem;
    `
interface Item {
    name: string;
    isFolder: boolean;
    fileName?: string | undefined
    items?: Item[];
}

export interface selectedFile {
    name: string | undefined;
    fileName: string | undefined;
}

interface FolderTreeProps {
    json: Item;
    selectedFile: selectedFile;
    setSelectedFile: Dispatch<SetStateAction<selectedFile>>;
}
const FolderTree = ({json, selectedFile, setSelectedFile} : FolderTreeProps) => {
    const [expand, setExpand] = useState(true);

    const handleClick = () => {
        setExpand(!expand);
    }
    const handleFileClick = (file : selectedFile) => {
        if (selectedFile.name === file.name) {
            setSelectedFile({name: undefined, fileName: undefined});
        }else{
            setSelectedFile(file);
        }
    };

    return (
        <Container>
            {json.isFolder ? (
                <>
                    <RowContainer type='folder' name={json.name} handleClick={handleClick}/>
                    <div style={{ display: expand ? 'block' : 'none' }}>
                        {json.items && json.items.map((item) => (
                            <FolderTree key={item.name}
                                        json={item}
                                        selectedFile={selectedFile}
                                        setSelectedFile={setSelectedFile}/>
                        ))}
                    </div>
                </>
            ): (
                <RowContainer type='file'
                              name={json.name}
                              handleClick={() => handleFileClick({name: json.name, fileName: json.fileName})}
                              isSelected={selectedFile.fileName === json.fileName}/>
            )}
        </Container>
    )
}

export default FolderTree;